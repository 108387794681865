<template>
  <div style="background: #17233d; color: #29ce29">
    <pre>{{ row.contact }}</pre>
  </div>
</template>

<script>
export default {
  name: "table-expand",
  props: {
    row: Object
  }
}
</script>

<style scoped>

</style>