function fetchData() {
    return {
        code: 1,
        cost: 38.36,
        ip: '120.235.189.197',
        data: {
            ispMaxMin: {
                "1": {
                    "name": "电信",
                    "data": {
                        "max": {
                            "node_name": "广东东莞电信",
                            "cost": 213.045
                        },
                        "min": {
                            "node_name": "北京电信",
                            "cost": 2.912
                        },
                        "avg": {
                            "node_name": "",
                            "cost": 33.025
                        }
                    }
                },
                "2": {
                    "name": "移动",
                    "data": {
                        "max": {
                            "node_name": "新疆昌吉回族自治州移动",
                            "cost": 79.748
                        },
                        "min": {
                            "node_name": "江苏苏州移动",
                            "cost": 6.877
                        },
                        "avg": {
                            "node_name": "",
                            "cost": 27.621
                        }
                    }
                },
                "3": {
                    "name": "联通",
                    "data": {
                        "max": {
                            "node_name": "新疆巴音郭楞蒙古自治州联通",
                            "cost": 62.588
                        },
                        "min": {
                            "node_name": "江苏苏州联通",
                            "cost": 5.277
                        },
                        "avg": {
                            "node_name": "",
                            "cost": 24.003
                        }
                    }
                }
            },
            maxMinAvg: {
                "max": {
                    "node_name": "广东东莞电信",
                    "cost": 213.045
                },
                "min": {
                    "node_name": "北京电信",
                    "cost": 2.912
                },
                "avg": {
                    "node_name": "",
                    "cost": 28.368
                }
            },
            provinceArr: [
                {
                    "name": "甘肃",
                    "value": 40.5,
                    "seven_area_name": "xi_bei"
                },
                {
                    "name": "安徽",
                    "value": 19.485,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "福建",
                    "value": 21.02,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "广东",
                    "value": 80.403,
                    "seven_area_name": "hua_nan"
                },
                {
                    "name": "北京",
                    "value": 7.472,
                    "seven_area_name": "hua_bei"
                },
                {
                    "name": "山东",
                    "value": 21.724,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "贵州",
                    "value": 24.056,
                    "seven_area_name": "xi_nan"
                },
                {
                    "name": "河南",
                    "value": 25.068,
                    "seven_area_name": "hua_zhong"
                },
                {
                    "name": "青海",
                    "value": 30.23,
                    "seven_area_name": "xi_bei"
                },
                {
                    "name": "湖北",
                    "value": 22.566,
                    "seven_area_name": "hua_zhong"
                },
                {
                    "name": "湖南",
                    "value": 24.848,
                    "seven_area_name": "hua_zhong"
                },
                {
                    "name": "重庆",
                    "value": 37.088,
                    "seven_area_name": "xi_nan"
                },
                {
                    "name": "宁夏",
                    "value": 21.576,
                    "seven_area_name": "xi_bei"
                },
                {
                    "name": "辽宁",
                    "value": 22.089,
                    "seven_area_name": "dong_bei"
                },
                {
                    "name": "陕西",
                    "value": 35.019,
                    "seven_area_name": "xi_bei"
                },
                {
                    "name": "上海",
                    "value": 24.619,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "海南",
                    "value": 19.756,
                    "seven_area_name": "hua_nan"
                },
                {
                    "name": "江苏",
                    "value": 5.231,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "山西",
                    "value": 25.571,
                    "seven_area_name": "hua_bei"
                },
                {
                    "name": "新疆",
                    "value": 67.73,
                    "seven_area_name": "xi_bei"
                },
                {
                    "name": "浙江",
                    "value": 14.127,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "黑龙江",
                    "value": 33.858,
                    "seven_area_name": "dong_bei"
                },
                {
                    "name": "江西",
                    "value": 20.105,
                    "seven_area_name": "hua_dong"
                },
                {
                    "name": "广西",
                    "value": 29.872,
                    "seven_area_name": "hua_nan"
                },
                {
                    "name": "吉林",
                    "value": 26.864,
                    "seven_area_name": "dong_bei"
                },
                {
                    "name": "内蒙古",
                    "value": 16.975,
                    "seven_area_name": "hua_bei"
                },
                {
                    "name": "四川",
                    "value": 41.28,
                    "seven_area_name": "xi_nan"
                },
                {
                    "name": "河北",
                    "value": 21.442,
                    "seven_area_name": "hua_bei"
                },
                {
                    "name": "云南",
                    "value": 48.747,
                    "seven_area_name": "xi_nan"
                },
                {
                    "name": "天津",
                    "value": 15.436,
                    "seven_area_name": "hua_bei"
                }
            ],
            ispByProvinceArr: [
                [
                    {
                        "name": "甘肃",
                        "value": 40.5,
                        "seven_area_name": "xi_bei"
                    },
                    {
                        "name": "安徽",
                        "value": 19.485,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "福建",
                        "value": 21.02,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "广东",
                        "value": 80.403,
                        "seven_area_name": "hua_nan"
                    },
                    {
                        "name": "北京",
                        "value": 7.472,
                        "seven_area_name": "hua_bei"
                    },
                    {
                        "name": "山东",
                        "value": 21.724,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "贵州",
                        "value": 24.056,
                        "seven_area_name": "xi_nan"
                    },
                    {
                        "name": "河南",
                        "value": 25.068,
                        "seven_area_name": "hua_zhong"
                    },
                    {
                        "name": "青海",
                        "value": 30.23,
                        "seven_area_name": "xi_bei"
                    },
                    {
                        "name": "湖北",
                        "value": 22.566,
                        "seven_area_name": "hua_zhong"
                    },
                    {
                        "name": "湖南",
                        "value": 24.848,
                        "seven_area_name": "hua_zhong"
                    },
                    {
                        "name": "重庆",
                        "value": 37.088,
                        "seven_area_name": "xi_nan"
                    },
                    {
                        "name": "宁夏",
                        "value": 21.576,
                        "seven_area_name": "xi_bei"
                    },
                    {
                        "name": "辽宁",
                        "value": 22.089,
                        "seven_area_name": "dong_bei"
                    },
                    {
                        "name": "陕西",
                        "value": 35.019,
                        "seven_area_name": "xi_bei"
                    },
                    {
                        "name": "上海",
                        "value": 24.619,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "海南",
                        "value": 19.756,
                        "seven_area_name": "hua_nan"
                    },
                    {
                        "name": "江苏",
                        "value": 5.231,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "山西",
                        "value": 25.571,
                        "seven_area_name": "hua_bei"
                    },
                    {
                        "name": "新疆",
                        "value": 67.73,
                        "seven_area_name": "xi_bei"
                    },
                    {
                        "name": "浙江",
                        "value": 14.127,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "黑龙江",
                        "value": 33.858,
                        "seven_area_name": "dong_bei"
                    },
                    {
                        "name": "江西",
                        "value": 20.105,
                        "seven_area_name": "hua_dong"
                    },
                    {
                        "name": "广西",
                        "value": 29.872,
                        "seven_area_name": "hua_nan"
                    },
                    {
                        "name": "吉林",
                        "value": 26.864,
                        "seven_area_name": "dong_bei"
                    },
                    {
                        "name": "内蒙古",
                        "value": 16.975,
                        "seven_area_name": "hua_bei"
                    },
                    {
                        "name": "四川",
                        "value": 41.28,
                        "seven_area_name": "xi_nan"
                    },
                    {
                        "name": "河北",
                        "value": 21.442,
                        "seven_area_name": "hua_bei"
                    },
                    {
                        "name": "云南",
                        "value": 48.747,
                        "seven_area_name": "xi_nan"
                    },
                    {
                        "name": "天津",
                        "value": 15.436,
                        "seven_area_name": "hua_bei"
                    }
                ],
                [
                    {
                        "name": "北京",
                        "value": 2.912,
                        "seven_area_name": "hua_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "江苏",
                        "value": 3.54,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "海南",
                        "value": 12.048,
                        "seven_area_name": "hua_nan",
                        "isp_name": "电信"
                    },
                    {
                        "name": "浙江",
                        "value": 16.26,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "湖北",
                        "value": 17.977,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "内蒙古",
                        "value": 18.175,
                        "seven_area_name": "hua_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "福建",
                        "value": 18.3,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "贵州",
                        "value": 18.459,
                        "seven_area_name": "xi_nan",
                        "isp_name": "电信"
                    },
                    {
                        "name": "辽宁",
                        "value": 19.772,
                        "seven_area_name": "dong_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "江西",
                        "value": 20.212,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "宁夏",
                        "value": 21.572,
                        "seven_area_name": "xi_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "河北",
                        "value": 22.788,
                        "seven_area_name": "hua_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "湖南",
                        "value": 24.38,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "广西",
                        "value": 24.828,
                        "seven_area_name": "hua_nan",
                        "isp_name": "电信"
                    },
                    {
                        "name": "山东",
                        "value": 27.36,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "河南",
                        "value": 28.147,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "青海",
                        "value": 28.336,
                        "seven_area_name": "xi_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "安徽",
                        "value": 28.602,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "云南",
                        "value": 31.732,
                        "seven_area_name": "xi_nan",
                        "isp_name": "电信"
                    },
                    {
                        "name": "上海",
                        "value": 32.88,
                        "seven_area_name": "hua_dong",
                        "isp_name": "电信"
                    },
                    {
                        "name": "吉林",
                        "value": 33.232,
                        "seven_area_name": "dong_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "黑龙江",
                        "value": 33.504,
                        "seven_area_name": "dong_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "重庆",
                        "value": 36.099,
                        "seven_area_name": "xi_nan",
                        "isp_name": "电信"
                    },
                    {
                        "name": "山西",
                        "value": 37.35,
                        "seven_area_name": "hua_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "四川",
                        "value": 39.883,
                        "seven_area_name": "xi_nan",
                        "isp_name": "电信"
                    },
                    {
                        "name": "甘肃",
                        "value": 40.5,
                        "seven_area_name": "xi_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "陕西",
                        "value": 44.967,
                        "seven_area_name": "xi_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "新疆",
                        "value": 60.854,
                        "seven_area_name": "xi_bei",
                        "isp_name": "电信"
                    },
                    {
                        "name": "广东",
                        "value": 213.045,
                        "seven_area_name": "hua_nan",
                        "isp_name": "电信"
                    }
                ],
                [
                    {
                        "name": "江苏",
                        "value": 6.877,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "北京",
                        "value": 8.02,
                        "seven_area_name": "hua_bei",
                        "isp_name": "移动"
                    },
                    {
                        "name": "浙江",
                        "value": 13.828,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "山西",
                        "value": 14.992,
                        "seven_area_name": "hua_bei",
                        "isp_name": "移动"
                    },
                    {
                        "name": "安徽",
                        "value": 16.021,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "山东",
                        "value": 19.429,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "江西",
                        "value": 20.092,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "河南",
                        "value": 20.196,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "湖北",
                        "value": 20.592,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "宁夏",
                        "value": 20.916,
                        "seven_area_name": "xi_bei",
                        "isp_name": "移动"
                    },
                    {
                        "name": "辽宁",
                        "value": 21.428,
                        "seven_area_name": "dong_bei",
                        "isp_name": "移动"
                    },
                    {
                        "name": "广东",
                        "value": 22.63,
                        "seven_area_name": "hua_nan",
                        "isp_name": "移动"
                    },
                    {
                        "name": "吉林",
                        "value": 25.02,
                        "seven_area_name": "dong_bei",
                        "isp_name": "移动"
                    },
                    {
                        "name": "陕西",
                        "value": 25.07,
                        "seven_area_name": "xi_bei",
                        "isp_name": "移动"
                    },
                    {
                        "name": "福建",
                        "value": 25.46,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "海南",
                        "value": 27.464,
                        "seven_area_name": "hua_nan",
                        "isp_name": "移动"
                    },
                    {
                        "name": "湖南",
                        "value": 29.776,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "上海",
                        "value": 32.021,
                        "seven_area_name": "hua_dong",
                        "isp_name": "移动"
                    },
                    {
                        "name": "重庆",
                        "value": 37.236,
                        "seven_area_name": "xi_nan",
                        "isp_name": "移动"
                    },
                    {
                        "name": "四川",
                        "value": 41.06,
                        "seven_area_name": "xi_nan",
                        "isp_name": "移动"
                    },
                    {
                        "name": "广西",
                        "value": 51.76,
                        "seven_area_name": "hua_nan",
                        "isp_name": "移动"
                    },
                    {
                        "name": "云南",
                        "value": 55.64,
                        "seven_area_name": "xi_nan",
                        "isp_name": "移动"
                    },
                    {
                        "name": "新疆",
                        "value": 79.748,
                        "seven_area_name": "xi_bei",
                        "isp_name": "移动"
                    }
                ],
                [
                    {
                        "name": "江苏",
                        "value": 5.277,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "广东",
                        "value": 5.535,
                        "seven_area_name": "hua_nan",
                        "isp_name": "联通"
                    },
                    {
                        "name": "上海",
                        "value": 8.956,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "北京",
                        "value": 11.484,
                        "seven_area_name": "hua_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "浙江",
                        "value": 12.294,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "广西",
                        "value": 13.028,
                        "seven_area_name": "hua_nan",
                        "isp_name": "联通"
                    },
                    {
                        "name": "安徽",
                        "value": 13.831,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "天津",
                        "value": 15.436,
                        "seven_area_name": "hua_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "内蒙古",
                        "value": 15.774,
                        "seven_area_name": "hua_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "山东",
                        "value": 18.382,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "福建",
                        "value": 19.301,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "江西",
                        "value": 20.012,
                        "seven_area_name": "hua_dong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "河北",
                        "value": 20.096,
                        "seven_area_name": "hua_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "湖南",
                        "value": 20.388,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "宁夏",
                        "value": 22.24,
                        "seven_area_name": "xi_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "吉林",
                        "value": 22.34,
                        "seven_area_name": "dong_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "山西",
                        "value": 24.372,
                        "seven_area_name": "hua_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "辽宁",
                        "value": 25.068,
                        "seven_area_name": "dong_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "河南",
                        "value": 26.86,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "湖北",
                        "value": 29.128,
                        "seven_area_name": "hua_zhong",
                        "isp_name": "联通"
                    },
                    {
                        "name": "贵州",
                        "value": 29.652,
                        "seven_area_name": "xi_nan",
                        "isp_name": "联通"
                    },
                    {
                        "name": "青海",
                        "value": 32.124,
                        "seven_area_name": "xi_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "黑龙江",
                        "value": 34.212,
                        "seven_area_name": "dong_bei",
                        "isp_name": "联通"
                    },
                    {
                        "name": "重庆",
                        "value": 37.928,
                        "seven_area_name": "xi_nan",
                        "isp_name": "联通"
                    },
                    {
                        "name": "四川",
                        "value": 42.896,
                        "seven_area_name": "xi_nan",
                        "isp_name": "联通"
                    },
                    {
                        "name": "云南",
                        "value": 58.868,
                        "seven_area_name": "xi_nan",
                        "isp_name": "联通"
                    },
                    {
                        "name": "新疆",
                        "value": 62.588,
                        "seven_area_name": "xi_bei",
                        "isp_name": "联通"
                    }
                ]
            ],
            result: [
                {
                    "node_id": 6,
                    "size": 64,
                    "ip": "220.181.38.149",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "河北秦皇岛电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 22.34,
                    "avg": 22.788,
                    "max": 23.58,
                    "session_id": "D4EE072E8A40",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.149) 64(92) bytes of data.\n72 bytes from 220.181.38.149: icmp_req=1 ttl=54 time=22.9 ms\n72 bytes from 220.181.38.149: icmp_req=2 ttl=54 time=22.3 ms\n72 bytes from 220.181.38.149: icmp_req=3 ttl=54 time=22.5 ms\n72 bytes from 220.181.38.149: icmp_req=4 ttl=54 time=22.5 ms\n72 bytes from 220.181.38.149: icmp_req=5 ttl=54 time=23.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 5075ms\nrtt min/avg/max/mdev = 22.340/22.788/23.580/0.445 ms",
                    "province": "3",
                    "area": "河北",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 8,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "河北邯郸联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 17.82,
                    "avg": 20.096,
                    "max": 26.2,
                    "session_id": "D4EE07434976",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from 110.242.68.4: icmp_req=1 ttl=56 time=17.8 ms\n72 bytes from 110.242.68.4: icmp_req=2 ttl=56 time=19.7 ms\n72 bytes from 110.242.68.4: icmp_req=3 ttl=56 time=26.2 ms\n72 bytes from 110.242.68.4: icmp_req=4 ttl=56 time=18.4 ms\n72 bytes from 110.242.68.4: icmp_req=5 ttl=56 time=18.2 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 17.820/20.096/26.200/3.121 ms",
                    "province": "3",
                    "area": "河北",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 12,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "陕西西安电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 27.945,
                    "avg": 44.967,
                    "max": 112.712,
                    "session_id": "thost_2",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=55 time=27.945 ms\n72 bytes from 14.215.177.38: seq=1 ttl=55 time=28.115 ms\n72 bytes from 14.215.177.38: seq=2 ttl=55 time=27.962 ms\n72 bytes from 14.215.177.38: seq=3 ttl=55 time=28.102 ms\n72 bytes from 14.215.177.38: seq=4 ttl=55 time=112.712 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 27.945/44.967/112.712 ms\n",
                    "province": "27",
                    "area": "陕西",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 13,
                    "size": 64,
                    "ip": "36.152.44.95",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "陕西西安移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 24.775,
                    "avg": 25.07,
                    "max": 25.273,
                    "session_id": "D4EE075433D0",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (36.152.44.95) 64(92) bytes of data.\n72 bytes from 36.152.44.95: icmp_req=1 ttl=56 time=25.2 ms\n72 bytes from 36.152.44.95: icmp_req=2 ttl=56 time=24.8 ms\n72 bytes from 36.152.44.95: icmp_req=3 ttl=56 time=24.7 ms\n72 bytes from 36.152.44.95: icmp_req=4 ttl=56 time=25.1 ms\n72 bytes from 36.152.44.95: icmp_req=5 ttl=56 time=25.2 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 8048ms\nrtt min/avg/max/mdev = 24.775/25.070/25.273/0.288 ms",
                    "province": "27",
                    "area": "陕西",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 18,
                    "size": 64,
                    "ip": "103.235.46.39",
                    "is_invalid_ip": 0,
                    "ip_from": "香港",
                    "node_name": "广东东莞电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 212.984,
                    "avg": 213.045,
                    "max": 213.119,
                    "session_id": "wy_3",
                    "host": "www.baidu.com",
                    "contact": "PING www.wshifen.com (103.235.46.39) 64(92) bytes of data.\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=1 ttl=45 time=213 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=2 ttl=45 time=212 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=3 ttl=45 time=213 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=4 ttl=45 time=213 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=5 ttl=45 time=213 ms\n\n--- www.wshifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4001ms\nrtt min/avg/max/mdev = 212.984/213.045/213.119/0.048 ms\n",
                    "province": "19",
                    "area": "广东",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 19,
                    "size": 64,
                    "ip": "103.235.46.39",
                    "is_invalid_ip": 0,
                    "ip_from": "香港",
                    "node_name": "广东深圳移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 21.332,
                    "avg": 22.63,
                    "max": 24.557,
                    "session_id": "wy_6",
                    "host": "www.baidu.com",
                    "contact": "PING www.wshifen.com (103.235.46.39) 64(92) bytes of data.\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=1 ttl=46 time=21.4 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=2 ttl=46 time=24.5 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=3 ttl=46 time=21.3 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=4 ttl=46 time=21.3 ms\n72 bytes from 103.235.46.39 (103.235.46.39): icmp_seq=5 ttl=46 time=24.5 ms\n\n--- www.wshifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 21.332/22.630/24.557/1.563 ms\n",
                    "province": "19",
                    "area": "广东",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 20,
                    "size": 64,
                    "ip": "163.177.151.110",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州联通",
                    "node_name": "广东东莞联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 4.801,
                    "avg": 5.535,
                    "max": 6.539,
                    "session_id": "venus_1067",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (163.177.151.110): 64 data bytes\n72 bytes from 163.177.151.110: seq=0 ttl=57 time=6.539 ms\n72 bytes from 163.177.151.110: seq=1 ttl=57 time=6.509 ms\n72 bytes from 163.177.151.110: seq=2 ttl=57 time=4.866 ms\n72 bytes from 163.177.151.110: seq=3 ttl=57 time=4.963 ms\n72 bytes from 163.177.151.110: seq=4 ttl=57 time=4.801 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 4.801/5.535/6.539 ms\n",
                    "province": "19",
                    "area": "广东",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 24,
                    "size": 64,
                    "ip": "180.101.49.12",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京电信",
                    "node_name": "浙江宁波电信",
                    "packets": 5,
                    "received": 4,
                    "packet_loss": 20,
                    "min": 16.217,
                    "avg": 16.26,
                    "max": 16.316,
                    "session_id": "venus_1046",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (180.101.49.12): 64 data bytes\n72 bytes from 180.101.49.12: seq=0 ttl=54 time=16.217 ms\n72 bytes from 180.101.49.12: seq=1 ttl=54 time=16.219 ms\n72 bytes from 180.101.49.12: seq=3 ttl=54 time=16.290 ms\n72 bytes from 180.101.49.12: seq=4 ttl=54 time=16.316 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 4 packets received, 20% packet loss\nround-trip min/avg/max = 16.217/16.260/16.316 ms\n",
                    "province": "11",
                    "area": "浙江",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 25,
                    "size": 64,
                    "ip": "36.152.44.95",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "浙江宁波移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 13.48,
                    "avg": 13.828,
                    "max": 14.44,
                    "session_id": "D4EE0755CC4A",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (36.152.44.95) 64(92) bytes of data.\n72 bytes from 36.152.44.95: icmp_req=1 ttl=56 time=13.8 ms\n72 bytes from 36.152.44.95: icmp_req=2 ttl=56 time=14.4 ms\n72 bytes from 36.152.44.95: icmp_req=3 ttl=56 time=13.4 ms\n72 bytes from 36.152.44.95: icmp_req=4 ttl=56 time=13.8 ms\n72 bytes from 36.152.44.95: icmp_req=5 ttl=56 time=13.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4004ms\nrtt min/avg/max/mdev = 13.480/13.828/14.440/0.336 ms",
                    "province": "11",
                    "area": "浙江",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 26,
                    "size": 64,
                    "ip": "112.80.248.75",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "浙江杭州联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 11.143,
                    "avg": 12.294,
                    "max": 13.471,
                    "session_id": "venus_1001",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (112.80.248.75): 64 data bytes\n72 bytes from 112.80.248.75: seq=0 ttl=57 time=11.387 ms\n72 bytes from 112.80.248.75: seq=1 ttl=57 time=11.143 ms\n72 bytes from 112.80.248.75: seq=2 ttl=57 time=13.260 ms\n72 bytes from 112.80.248.75: seq=3 ttl=57 time=13.471 ms\n72 bytes from 112.80.248.75: seq=4 ttl=57 time=12.209 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 11.143/12.294/13.471 ms\n",
                    "province": "11",
                    "area": "浙江",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 30,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "福建南平电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 17.874,
                    "avg": 18.3,
                    "max": 19.313,
                    "session_id": "venus_1030",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=56 time=18.043 ms\n72 bytes from 14.215.177.38: seq=1 ttl=56 time=19.313 ms\n72 bytes from 14.215.177.38: seq=2 ttl=56 time=17.902 ms\n72 bytes from 14.215.177.38: seq=3 ttl=56 time=17.874 ms\n72 bytes from 14.215.177.38: seq=4 ttl=56 time=18.372 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 17.874/18.300/19.313 ms\n",
                    "province": "13",
                    "area": "福建",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 31,
                    "size": 64,
                    "ip": "36.152.44.96",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "福建厦门移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 25.325,
                    "avg": 25.46,
                    "max": 25.699,
                    "session_id": "venus_1031",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (36.152.44.96): 64 data bytes\n72 bytes from 36.152.44.96: seq=0 ttl=56 time=25.699 ms\n72 bytes from 36.152.44.96: seq=1 ttl=56 time=25.325 ms\n72 bytes from 36.152.44.96: seq=2 ttl=56 time=25.343 ms\n72 bytes from 36.152.44.96: seq=3 ttl=56 time=25.476 ms\n72 bytes from 36.152.44.96: seq=4 ttl=56 time=25.457 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 25.325/25.460/25.699 ms\n",
                    "province": "13",
                    "area": "福建",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 32,
                    "size": 64,
                    "ip": "163.177.151.109",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州联通",
                    "node_name": "福建龙岩联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 18.497,
                    "avg": 19.301,
                    "max": 21.968,
                    "session_id": "venus_1054",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (163.177.151.109): 64 data bytes\n72 bytes from 163.177.151.109: seq=0 ttl=56 time=18.497 ms\n72 bytes from 163.177.151.109: seq=1 ttl=56 time=18.725 ms\n72 bytes from 163.177.151.109: seq=2 ttl=56 time=18.581 ms\n72 bytes from 163.177.151.109: seq=3 ttl=56 time=18.738 ms\n72 bytes from 163.177.151.109: seq=4 ttl=56 time=21.968 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 18.497/19.301/21.968 ms\n",
                    "province": "13",
                    "area": "福建",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 36,
                    "size": 64,
                    "ip": "180.101.49.12",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京电信",
                    "node_name": "江苏淮安电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 3.417,
                    "avg": 3.54,
                    "max": 3.606,
                    "session_id": "venus_1036",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (180.101.49.12): 64 data bytes\n72 bytes from 180.101.49.12: seq=0 ttl=55 time=3.601 ms\n72 bytes from 180.101.49.12: seq=1 ttl=55 time=3.606 ms\n72 bytes from 180.101.49.12: seq=2 ttl=55 time=3.486 ms\n72 bytes from 180.101.49.12: seq=3 ttl=55 time=3.593 ms\n72 bytes from 180.101.49.12: seq=4 ttl=55 time=3.417 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 3.417/3.540/3.606 ms\n",
                    "province": "10",
                    "area": "江苏",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 37,
                    "size": 64,
                    "ip": "36.152.44.95",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "江苏苏州移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 6.838,
                    "avg": 6.877,
                    "max": 6.986,
                    "session_id": "venus_1039",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (36.152.44.95): 64 data bytes\n72 bytes from 36.152.44.95: seq=0 ttl=59 time=6.986 ms\n72 bytes from 36.152.44.95: seq=1 ttl=59 time=6.862 ms\n72 bytes from 36.152.44.95: seq=2 ttl=59 time=6.863 ms\n72 bytes from 36.152.44.95: seq=3 ttl=59 time=6.839 ms\n72 bytes from 36.152.44.95: seq=4 ttl=59 time=6.838 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 6.838/6.877/6.986 ms\n",
                    "province": "10",
                    "area": "江苏",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 38,
                    "size": 64,
                    "ip": "112.80.248.76",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "江苏苏州联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 5.212,
                    "avg": 5.277,
                    "max": 5.381,
                    "session_id": "venus_1024",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (112.80.248.76): 64 data bytes\n72 bytes from 112.80.248.76: seq=0 ttl=58 time=5.212 ms\n72 bytes from 112.80.248.76: seq=1 ttl=58 time=5.257 ms\n72 bytes from 112.80.248.76: seq=2 ttl=58 time=5.309 ms\n72 bytes from 112.80.248.76: seq=3 ttl=58 time=5.381 ms\n72 bytes from 112.80.248.76: seq=4 ttl=58 time=5.227 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 5.212/5.277/5.381 ms\n",
                    "province": "10",
                    "area": "江苏",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 42,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "上海电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 32.83,
                    "avg": 32.88,
                    "max": 32.927,
                    "session_id": "wy_2",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (14.215.177.38) 64(92) bytes of data.\n72 bytes from 14.215.177.38: icmp_seq=1 ttl=50 time=32.8 ms\n72 bytes from 14.215.177.38: icmp_seq=2 ttl=50 time=32.9 ms\n72 bytes from 14.215.177.38: icmp_seq=3 ttl=50 time=32.8 ms\n72 bytes from 14.215.177.38: icmp_seq=4 ttl=50 time=32.8 ms\n72 bytes from 14.215.177.38: icmp_seq=5 ttl=50 time=32.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 32.830/32.880/32.927/0.118 ms\n",
                    "province": "9",
                    "area": "上海",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 43,
                    "size": 64,
                    "ip": "14.215.177.39",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "上海移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 32.014,
                    "avg": 32.021,
                    "max": 32.028,
                    "session_id": "wy_1",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (14.215.177.39) from 117.184.33.35 : 64(92) bytes of data.\n72 bytes from 14.215.177.39: icmp_seq=1 ttl=48 time=32.0 ms\n72 bytes from 14.215.177.39: icmp_seq=2 ttl=48 time=32.0 ms\n72 bytes from 14.215.177.39: icmp_seq=3 ttl=48 time=32.0 ms\n72 bytes from 14.215.177.39: icmp_seq=4 ttl=48 time=32.0 ms\n72 bytes from 14.215.177.39: icmp_seq=5 ttl=48 time=32.0 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 32.014/32.021/32.028/0.226 ms\n",
                    "province": "9",
                    "area": "上海",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 44,
                    "size": 64,
                    "ip": "112.80.248.76",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "上海联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 8.18,
                    "avg": 8.956,
                    "max": 9.6,
                    "session_id": "D4EE07539248",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (112.80.248.76) 64(92) bytes of data.\n72 bytes from 112.80.248.76: icmp_req=1 ttl=56 time=9.60 ms\n72 bytes from 112.80.248.76: icmp_req=2 ttl=56 time=9.12 ms\n72 bytes from 112.80.248.76: icmp_req=3 ttl=56 time=8.94 ms\n72 bytes from 112.80.248.76: icmp_req=4 ttl=56 time=8.94 ms\n72 bytes from 112.80.248.76: icmp_req=5 ttl=56 time=8.18 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 8.180/8.956/9.600/0.457 ms",
                    "province": "9",
                    "area": "上海",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 48,
                    "size": 64,
                    "ip": "220.181.38.150",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "北京电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 2.84,
                    "avg": 2.912,
                    "max": 3.08,
                    "session_id": "D4EE074193A8",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.150) 64(92) bytes of data.\n72 bytes from 220.181.38.150: icmp_req=1 ttl=55 time=2.90 ms\n72 bytes from 220.181.38.150: icmp_req=2 ttl=55 time=3.08 ms\n72 bytes from 220.181.38.150: icmp_req=3 ttl=55 time=2.84 ms\n72 bytes from 220.181.38.150: icmp_req=4 ttl=55 time=2.84 ms\n72 bytes from 220.181.38.150: icmp_req=5 ttl=55 time=2.90 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 2.840/2.912/3.080/0.088 ms",
                    "province": "1",
                    "area": "北京",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 49,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "北京移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 7.76,
                    "avg": 8.02,
                    "max": 8.4,
                    "session_id": "D4EE0756245E",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from 39.156.66.14: icmp_req=1 ttl=53 time=8.40 ms\n72 bytes from 39.156.66.14: icmp_req=2 ttl=53 time=7.78 ms\n72 bytes from 39.156.66.14: icmp_req=3 ttl=53 time=7.76 ms\n72 bytes from 39.156.66.14: icmp_req=4 ttl=53 time=8.32 ms\n72 bytes from 39.156.66.14: icmp_req=5 ttl=53 time=7.84 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4008ms\nrtt min/avg/max/mdev = 7.760/8.020/8.400/0.280 ms",
                    "province": "1",
                    "area": "北京",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 50,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "北京联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 11.18,
                    "avg": 11.484,
                    "max": 11.76,
                    "session_id": "D4EE075A66D6",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (110.242.68.4): 64 data bytes\n72 bytes from 110.242.68.4: seq=0 ttl=54 time=11.760 ms\n72 bytes from 110.242.68.4: seq=1 ttl=54 time=11.180 ms\n72 bytes from 110.242.68.4: seq=2 ttl=54 time=11.620 ms\n72 bytes from 110.242.68.4: seq=3 ttl=54 time=11.440 ms\n72 bytes from 110.242.68.4: seq=4 ttl=54 time=11.420 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 11.180/11.484/11.760 ms",
                    "province": "1",
                    "area": "北京",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 54,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "云南玉溪电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 31.48,
                    "avg": 31.732,
                    "max": 32.12,
                    "session_id": "D4EE073C7436",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (14.215.177.38) 64(92) bytes of data.\n72 bytes from 14.215.177.38: icmp_req=1 ttl=56 time=31.9 ms\n72 bytes from 14.215.177.38: icmp_req=2 ttl=56 time=32.1 ms\n72 bytes from 14.215.177.38: icmp_req=3 ttl=56 time=31.5 ms\n72 bytes from 14.215.177.38: icmp_req=4 ttl=56 time=31.6 ms\n72 bytes from 14.215.177.38: icmp_req=5 ttl=56 time=31.4 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4007ms\nrtt min/avg/max/mdev = 31.480/31.732/32.120/0.252 ms",
                    "province": "25",
                    "area": "云南",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 55,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "云南昆明移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 55.48,
                    "avg": 55.64,
                    "max": 55.94,
                    "session_id": "D4EE074090D6",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from 110.242.68.4: icmp_req=1 ttl=50 time=55.9 ms\n72 bytes from 110.242.68.4: icmp_req=2 ttl=50 time=55.7 ms\n72 bytes from 110.242.68.4: icmp_req=3 ttl=50 time=55.4 ms\n72 bytes from 110.242.68.4: icmp_req=4 ttl=50 time=55.4 ms\n72 bytes from 110.242.68.4: icmp_req=5 ttl=50 time=55.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 55.480/55.640/55.940/0.173 ms",
                    "province": "25",
                    "area": "云南",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 56,
                    "size": 64,
                    "ip": "163.177.151.109",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州联通",
                    "node_name": "云南联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 43.28,
                    "avg": 58.868,
                    "max": 99.28,
                    "session_id": "D4EE0735B012",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (163.177.151.109) 64(92) bytes of data.\n72 bytes from 163.177.151.109: icmp_req=1 ttl=54 time=43.2 ms\n72 bytes from 163.177.151.109: icmp_req=2 ttl=54 time=43.6 ms\n72 bytes from 163.177.151.109: icmp_req=3 ttl=54 time=99.2 ms\n72 bytes from 163.177.151.109: icmp_req=4 ttl=54 time=50.3 ms\n72 bytes from 163.177.151.109: icmp_req=5 ttl=54 time=57.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4007ms\nrtt min/avg/max/mdev = 43.280/58.868/99.280/20.893 ms",
                    "province": "25",
                    "area": "云南",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 60,
                    "size": 64,
                    "ip": "180.101.49.11",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京电信",
                    "node_name": "山西晋城电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 37.32,
                    "avg": 37.35,
                    "max": 37.413,
                    "session_id": "venus_1050",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (180.101.49.11): 64 data bytes\n72 bytes from 180.101.49.11: seq=0 ttl=53 time=37.413 ms\n72 bytes from 180.101.49.11: seq=1 ttl=53 time=37.352 ms\n72 bytes from 180.101.49.11: seq=2 ttl=53 time=37.337 ms\n72 bytes from 180.101.49.11: seq=3 ttl=53 time=37.320 ms\n72 bytes from 180.101.49.11: seq=4 ttl=53 time=37.330 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 37.320/37.350/37.413 ms\n",
                    "province": "4",
                    "area": "山西",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 61,
                    "size": 64,
                    "ip": "39.156.66.18",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "山西太原移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 14.82,
                    "avg": 14.992,
                    "max": 15.12,
                    "session_id": "D4EE074B64C0",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.18) 64(92) bytes of data.\n72 bytes from 39.156.66.18: icmp_req=1 ttl=52 time=15.1 ms\n72 bytes from 39.156.66.18: icmp_req=2 ttl=52 time=14.9 ms\n72 bytes from 39.156.66.18: icmp_req=3 ttl=52 time=14.8 ms\n72 bytes from 39.156.66.18: icmp_req=4 ttl=52 time=15.0 ms\n72 bytes from 39.156.66.18: icmp_req=5 ttl=52 time=15.0 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 14.820/14.992/15.120/0.109 ms",
                    "province": "4",
                    "area": "山西",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 62,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "山西长治联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 24.2,
                    "avg": 24.372,
                    "max": 24.8,
                    "session_id": "D4EE074C4D24",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from 110.242.68.4: icmp_req=1 ttl=55 time=24.2 ms\n72 bytes from 110.242.68.4: icmp_req=2 ttl=55 time=24.2 ms\n72 bytes from 110.242.68.4: icmp_req=3 ttl=55 time=24.2 ms\n72 bytes from 110.242.68.4: icmp_req=4 ttl=55 time=24.3 ms\n72 bytes from 110.242.68.4: icmp_req=5 ttl=55 time=24.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 24.200/24.372/24.800/0.218 ms",
                    "province": "4",
                    "area": "山西",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 66,
                    "size": 64,
                    "ip": "220.181.38.149",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "河南信阳电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 27.626,
                    "avg": 28.147,
                    "max": 28.866,
                    "session_id": "venus_1058",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (220.181.38.149): 64 data bytes\n72 bytes from 220.181.38.149: seq=0 ttl=54 time=28.403 ms\n72 bytes from 220.181.38.149: seq=1 ttl=54 time=28.034 ms\n72 bytes from 220.181.38.149: seq=2 ttl=54 time=27.626 ms\n72 bytes from 220.181.38.149: seq=3 ttl=54 time=27.807 ms\n72 bytes from 220.181.38.149: seq=4 ttl=54 time=28.866 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 27.626/28.147/28.866 ms\n",
                    "province": "16",
                    "area": "河南",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 67,
                    "size": 64,
                    "ip": "39.156.66.18",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "河南驻马店移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 19.06,
                    "avg": 20.196,
                    "max": 21.74,
                    "session_id": "D4EE0749171A",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.18) 64(92) bytes of data.\n72 bytes from 39.156.66.18: icmp_req=1 ttl=53 time=20.8 ms\n72 bytes from 39.156.66.18: icmp_req=2 ttl=53 time=19.0 ms\n72 bytes from 39.156.66.18: icmp_req=3 ttl=53 time=19.0 ms\n72 bytes from 39.156.66.18: icmp_req=4 ttl=53 time=20.2 ms\n72 bytes from 39.156.66.18: icmp_req=5 ttl=53 time=21.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 19.060/20.196/21.740/1.031 ms",
                    "province": "16",
                    "area": "河南",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 68,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "河南新乡联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 26.54,
                    "avg": 26.86,
                    "max": 27.1,
                    "session_id": "D4EE07362AA2",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from 110.242.68.4: icmp_req=1 ttl=55 time=26.6 ms\n72 bytes from 110.242.68.4: icmp_req=2 ttl=55 time=27.0 ms\n72 bytes from 110.242.68.4: icmp_req=3 ttl=55 time=27.1 ms\n72 bytes from 110.242.68.4: icmp_req=4 ttl=55 time=26.5 ms\n72 bytes from 110.242.68.4: icmp_req=5 ttl=55 time=27.0 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4004ms\nrtt min/avg/max/mdev = 26.540/26.860/27.100/0.239 ms",
                    "province": "16",
                    "area": "河南",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 72,
                    "size": 64,
                    "ip": "180.101.49.12",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京电信",
                    "node_name": "山东泰安电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 27.28,
                    "avg": 27.36,
                    "max": 27.46,
                    "session_id": "D4EE073F701C",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (180.101.49.12) 64(92) bytes of data.\n72 bytes from 180.101.49.12: icmp_req=1 ttl=54 time=27.4 ms\n72 bytes from 180.101.49.12: icmp_req=2 ttl=54 time=27.3 ms\n72 bytes from 180.101.49.12: icmp_req=3 ttl=54 time=27.2 ms\n72 bytes from 180.101.49.12: icmp_req=4 ttl=54 time=27.4 ms\n72 bytes from 180.101.49.12: icmp_req=5 ttl=54 time=27.3 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 27.280/27.360/27.460/0.068 ms",
                    "province": "15",
                    "area": "山东",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 73,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "山东济南移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 17.248,
                    "avg": 19.429,
                    "max": 23.785,
                    "session_id": "venus_1021",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (39.156.66.14): 64 data bytes\n72 bytes from 39.156.66.14: seq=0 ttl=54 time=20.963 ms\n72 bytes from 39.156.66.14: seq=1 ttl=54 time=17.327 ms\n72 bytes from 39.156.66.14: seq=2 ttl=54 time=17.248 ms\n72 bytes from 39.156.66.14: seq=3 ttl=54 time=17.824 ms\n72 bytes from 39.156.66.14: seq=4 ttl=54 time=23.785 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 17.248/19.429/23.785 ms\n",
                    "province": "15",
                    "area": "山东",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 74,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "山东德州移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 18.092,
                    "avg": 18.382,
                    "max": 18.884,
                    "session_id": "venus_1043",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (39.156.66.14): 64 data bytes\n72 bytes from 39.156.66.14: seq=0 ttl=54 time=18.539 ms\n72 bytes from 39.156.66.14: seq=1 ttl=54 time=18.122 ms\n72 bytes from 39.156.66.14: seq=2 ttl=54 time=18.092 ms\n72 bytes from 39.156.66.14: seq=3 ttl=54 time=18.884 ms\n72 bytes from 39.156.66.14: seq=4 ttl=54 time=18.275 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 18.092/18.382/18.884 ms\n",
                    "province": "15",
                    "area": "山东",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 78,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "湖北武汉电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 17.857,
                    "avg": 17.977,
                    "max": 18.257,
                    "session_id": "thost_4",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=54 time=18.257 ms\n72 bytes from 14.215.177.38: seq=1 ttl=54 time=17.950 ms\n72 bytes from 14.215.177.38: seq=2 ttl=54 time=17.857 ms\n72 bytes from 14.215.177.38: seq=3 ttl=54 time=17.922 ms\n72 bytes from 14.215.177.38: seq=4 ttl=54 time=17.903 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 17.857/17.977/18.257 ms\n",
                    "province": "17",
                    "area": "湖北",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 79,
                    "size": 64,
                    "ip": "36.152.44.95",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "湖北宜昌移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 20.44,
                    "avg": 20.592,
                    "max": 20.82,
                    "session_id": "D4EE075DB4FE",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (36.152.44.95) 64(92) bytes of data.\n72 bytes from localhost (36.152.44.95): icmp_req=1 ttl=56 time=20.8 ms\n72 bytes from localhost (36.152.44.95): icmp_req=2 ttl=56 time=20.5 ms\n72 bytes from localhost (36.152.44.95): icmp_req=3 ttl=56 time=20.4 ms\n72 bytes from localhost (36.152.44.95): icmp_req=4 ttl=56 time=20.5 ms\n72 bytes from localhost (36.152.44.95): icmp_req=5 ttl=56 time=20.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 20.440/20.592/20.820/0.124 ms",
                    "province": "17",
                    "area": "湖北",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 80,
                    "size": 64,
                    "ip": "112.80.248.76",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "湖北宜昌联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 28.72,
                    "avg": 29.128,
                    "max": 29.5,
                    "session_id": "D4EE071CE01C",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (112.80.248.76) 64(92) bytes of data.\n72 bytes from www.baidu.com (112.80.248.76): icmp_req=1 ttl=55 time=29.3 ms\n72 bytes from www.baidu.com (112.80.248.76): icmp_req=2 ttl=55 time=29.5 ms\n72 bytes from 112.80.248.76: icmp_req=3 ttl=55 time=28.7 ms\n72 bytes from 112.80.248.76: icmp_req=4 ttl=55 time=29.3 ms\n72 bytes from 112.80.248.76: icmp_req=5 ttl=55 time=28.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 28.720/29.128/29.500/0.330 ms",
                    "province": "17",
                    "area": "湖北",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 84,
                    "size": 64,
                    "ip": "220.181.38.149",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "吉林白山电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 32.58,
                    "avg": 33.232,
                    "max": 33.62,
                    "session_id": "D4EE0749249E",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.149) 64(92) bytes of data.\n72 bytes from 220.181.38.149: icmp_req=1 ttl=54 time=33.5 ms\n72 bytes from 220.181.38.149: icmp_req=2 ttl=54 time=33.2 ms\n72 bytes from 220.181.38.149: icmp_req=3 ttl=54 time=32.5 ms\n72 bytes from 220.181.38.149: icmp_req=4 ttl=54 time=33.2 ms\n72 bytes from 220.181.38.149: icmp_req=5 ttl=54 time=33.6 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4011ms\nrtt min/avg/max/mdev = 32.580/33.232/33.620/0.363 ms",
                    "province": "7",
                    "area": "吉林",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 85,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "吉林吉林市移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 24.92,
                    "avg": 25.02,
                    "max": 25.18,
                    "session_id": "D4EE07492336",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from www.baidu.com (39.156.66.14): icmp_req=1 ttl=52 time=24.9 ms\n72 bytes from www.baidu.com (39.156.66.14): icmp_req=2 ttl=52 time=25.0 ms\n72 bytes from www.baidu.com (39.156.66.14): icmp_req=3 ttl=52 time=25.1 ms\n72 bytes from www.baidu.com (39.156.66.14): icmp_req=4 ttl=52 time=25.0 ms\n72 bytes from www.baidu.com (39.156.66.14): icmp_req=5 ttl=52 time=24.9 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4004ms\nrtt min/avg/max/mdev = 24.920/25.020/25.180/0.086 ms",
                    "province": "7",
                    "area": "吉林",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 86,
                    "size": 64,
                    "ip": "182.61.200.6",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "吉林长春联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 22.18,
                    "avg": 22.34,
                    "max": 22.48,
                    "session_id": "D4EE0721590E",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (182.61.200.6) 64(92) bytes of data.\n72 bytes from 182.61.200.6: icmp_req=1 ttl=51 time=22.4 ms\n72 bytes from 182.61.200.6: icmp_req=2 ttl=51 time=22.1 ms\n72 bytes from 182.61.200.6: icmp_req=3 ttl=51 time=22.2 ms\n72 bytes from 182.61.200.6: icmp_req=4 ttl=51 time=22.4 ms\n72 bytes from 182.61.200.6: icmp_req=5 ttl=51 time=22.2 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 22073ms\nrtt min/avg/max/mdev = 22.180/22.340/22.480/0.120 ms",
                    "province": "7",
                    "area": "吉林",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 92,
                    "size": 64,
                    "ip": "110.242.68.3",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "天津联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 9.94,
                    "avg": 15.436,
                    "max": 34.66,
                    "session_id": "D4EE0713AB88",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.3) 64(92) bytes of data.\n72 bytes from 110.242.68.3: icmp_req=1 ttl=53 time=9.94 ms\n72 bytes from 110.242.68.3: icmp_req=2 ttl=53 time=34.6 ms\n72 bytes from 110.242.68.3: icmp_req=3 ttl=53 time=11.3 ms\n72 bytes from 110.242.68.3: icmp_req=4 ttl=53 time=10.7 ms\n72 bytes from 110.242.68.3: icmp_req=5 ttl=53 time=10.4 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 9.940/15.436/34.660/9.623 ms",
                    "province": "2",
                    "area": "天津",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 96,
                    "size": 64,
                    "ip": "220.181.38.149",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "内蒙古乌兰察布电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 17.173,
                    "avg": 18.175,
                    "max": 21.856,
                    "session_id": "venus_1011",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (220.181.38.149): 64 data bytes\n72 bytes from 220.181.38.149: seq=0 ttl=54 time=17.335 ms\n72 bytes from 220.181.38.149: seq=1 ttl=54 time=17.297 ms\n72 bytes from 220.181.38.149: seq=2 ttl=54 time=17.216 ms\n72 bytes from 220.181.38.149: seq=3 ttl=54 time=17.173 ms\n72 bytes from 220.181.38.149: seq=4 ttl=54 time=21.856 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 17.173/18.175/21.856 ms\n",
                    "province": "5",
                    "area": "内蒙古",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 98,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "内蒙古呼和浩特联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 15.689,
                    "avg": 15.774,
                    "max": 15.863,
                    "session_id": "D4EE07627DEC",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from 110.242.68.4: icmp_req=1 ttl=55 time=15.8 ms\n72 bytes from 110.242.68.4: icmp_req=2 ttl=55 time=15.7 ms\n72 bytes from 110.242.68.4: icmp_req=3 ttl=55 time=15.7 ms\n72 bytes from 110.242.68.4: icmp_req=4 ttl=55 time=15.6 ms\n72 bytes from 110.242.68.4: icmp_req=5 ttl=55 time=15.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 15.689/15.774/15.863/0.171 ms",
                    "province": "5",
                    "area": "内蒙古",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 102,
                    "size": 64,
                    "ip": "14.215.177.39",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "湖南益阳电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 24.1,
                    "avg": 24.38,
                    "max": 24.54,
                    "session_id": "D4EE0751CB8C",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (14.215.177.39) 64(92) bytes of data.\n72 bytes from 14.215.177.39: icmp_req=1 ttl=55 time=24.5 ms\n72 bytes from 14.215.177.39: icmp_req=2 ttl=55 time=24.4 ms\n72 bytes from 14.215.177.39: icmp_req=3 ttl=55 time=24.5 ms\n72 bytes from 14.215.177.39: icmp_req=4 ttl=55 time=24.2 ms\n72 bytes from 14.215.177.39: icmp_req=5 ttl=55 time=24.1 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 24.100/24.380/24.540/0.172 ms",
                    "province": "18",
                    "area": "湖南",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 103,
                    "size": 64,
                    "ip": "183.232.231.172",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州移动",
                    "node_name": "湖南益阳移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 26.32,
                    "avg": 29.776,
                    "max": 32.04,
                    "session_id": "D4EE071DB502",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (183.232.231.172) 64(92) bytes of data.\n72 bytes from localhost (183.232.231.172): icmp_req=1 ttl=53 time=30.7 ms\n72 bytes from localhost (183.232.231.172): icmp_req=2 ttl=53 time=26.3 ms\n72 bytes from localhost (183.232.231.172): icmp_req=3 ttl=53 time=32.0 ms\n72 bytes from localhost (183.232.231.172): icmp_req=4 ttl=53 time=28.5 ms\n72 bytes from localhost (183.232.231.172): icmp_req=5 ttl=53 time=31.2 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 26.320/29.776/32.040/2.078 ms",
                    "province": "18",
                    "area": "湖南",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 104,
                    "size": 64,
                    "ip": "112.80.248.75",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "湖南长沙联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 19.74,
                    "avg": 20.388,
                    "max": 22.42,
                    "session_id": "D4EE0710A53A",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (112.80.248.75) 64(92) bytes of data.\n72 bytes from 112.80.248.75: icmp_req=1 ttl=56 time=20.0 ms\n72 bytes from 112.80.248.75: icmp_req=2 ttl=56 time=22.4 ms\n72 bytes from 112.80.248.75: icmp_req=3 ttl=56 time=19.9 ms\n72 bytes from 112.80.248.75: icmp_req=4 ttl=56 time=19.8 ms\n72 bytes from 112.80.248.75: icmp_req=5 ttl=56 time=19.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4003ms\nrtt min/avg/max/mdev = 19.740/20.388/22.420/1.019 ms",
                    "province": "18",
                    "area": "湖南",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 108,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "贵州贵阳电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 18.361,
                    "avg": 18.459,
                    "max": 18.553,
                    "session_id": "thost_6",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=49 time=18.467 ms\n72 bytes from 14.215.177.38: seq=1 ttl=49 time=18.361 ms\n72 bytes from 14.215.177.38: seq=2 ttl=49 time=18.553 ms\n72 bytes from 14.215.177.38: seq=3 ttl=49 time=18.484 ms\n72 bytes from 14.215.177.38: seq=4 ttl=49 time=18.430 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 18.361/18.459/18.553 ms\n",
                    "province": "24",
                    "area": "贵州",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 110,
                    "size": 64,
                    "ip": "163.177.151.110",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州联通",
                    "node_name": "贵州黔南布依族苗族自治州联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 29.58,
                    "avg": 29.652,
                    "max": 29.74,
                    "session_id": "D4EE07075F66",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (163.177.151.110) 64(92) bytes of data.\n72 bytes from 163.177.151.110: icmp_req=1 ttl=56 time=29.7 ms\n72 bytes from 163.177.151.110: icmp_req=2 ttl=56 time=29.7 ms\n72 bytes from 163.177.151.110: icmp_req=3 ttl=56 time=29.5 ms\n72 bytes from 163.177.151.110: icmp_req=4 ttl=56 time=29.6 ms\n72 bytes from 163.177.151.110: icmp_req=5 ttl=56 time=29.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 29.580/29.652/29.740/0.064 ms",
                    "province": "24",
                    "area": "贵州",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 114,
                    "size": 64,
                    "ip": "14.215.177.39",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "江西南昌电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 19.96,
                    "avg": 20.212,
                    "max": 20.48,
                    "session_id": "D4EE072C966A",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.39): 64 data bytes\n72 bytes from 14.215.177.39: seq=0 ttl=55 time=20.480 ms\n72 bytes from 14.215.177.39: seq=1 ttl=55 time=20.360 ms\n72 bytes from 14.215.177.39: seq=2 ttl=55 time=20.060 ms\n72 bytes from 14.215.177.39: seq=3 ttl=55 time=20.200 ms\n72 bytes from 14.215.177.39: seq=4 ttl=55 time=19.960 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 19.960/20.212/20.480 ms",
                    "province": "14",
                    "area": "江西",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 115,
                    "size": 64,
                    "ip": "36.152.44.96",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "江西萍乡移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 19.76,
                    "avg": 20.092,
                    "max": 20.68,
                    "session_id": "D4EE0740AA1C",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (36.152.44.96) 64(92) bytes of data.\n72 bytes from localhost (36.152.44.96): icmp_req=1 ttl=56 time=20.2 ms\n72 bytes from localhost (36.152.44.96): icmp_req=2 ttl=56 time=19.7 ms\n72 bytes from localhost (36.152.44.96): icmp_req=3 ttl=56 time=20.6 ms\n72 bytes from localhost (36.152.44.96): icmp_req=4 ttl=56 time=19.9 ms\n72 bytes from localhost (36.152.44.96): icmp_req=5 ttl=56 time=19.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4003ms\nrtt min/avg/max/mdev = 19.760/20.092/20.680/0.340 ms",
                    "province": "14",
                    "area": "江西",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 116,
                    "size": 64,
                    "ip": "112.80.248.76",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "江西南昌联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 19.72,
                    "avg": 20.012,
                    "max": 20.24,
                    "session_id": "D4EE0729B540",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (112.80.248.76) 64(92) bytes of data.\n72 bytes from 112.80.248.76: icmp_req=1 ttl=53 time=20.2 ms\n72 bytes from 112.80.248.76: icmp_req=2 ttl=53 time=20.0 ms\n72 bytes from 112.80.248.76: icmp_req=3 ttl=53 time=19.9 ms\n72 bytes from 112.80.248.76: icmp_req=4 ttl=53 time=19.7 ms\n72 bytes from 112.80.248.76: icmp_req=5 ttl=53 time=20.1 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 19.720/20.012/20.240/0.174 ms",
                    "province": "14",
                    "area": "江西",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 120,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "安徽宣城电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 28.392,
                    "avg": 28.602,
                    "max": 28.758,
                    "session_id": "venus_1018",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=56 time=28.531 ms\n72 bytes from 14.215.177.38: seq=1 ttl=56 time=28.628 ms\n72 bytes from 14.215.177.38: seq=2 ttl=56 time=28.704 ms\n72 bytes from 14.215.177.38: seq=3 ttl=56 time=28.758 ms\n72 bytes from 14.215.177.38: seq=4 ttl=56 time=28.392 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 28.392/28.602/28.758 ms\n",
                    "province": "12",
                    "area": "安徽",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 121,
                    "size": 64,
                    "ip": "36.152.44.96",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京移动",
                    "node_name": "安徽宣城移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 13.883,
                    "avg": 16.021,
                    "max": 23.984,
                    "session_id": "venus_1051",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (36.152.44.96): 64 data bytes\n72 bytes from 36.152.44.96: seq=0 ttl=56 time=14.245 ms\n72 bytes from 36.152.44.96: seq=1 ttl=56 time=14.024 ms\n72 bytes from 36.152.44.96: seq=2 ttl=56 time=13.883 ms\n72 bytes from 36.152.44.96: seq=3 ttl=56 time=23.984 ms\n72 bytes from 36.152.44.96: seq=4 ttl=56 time=13.970 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 13.883/16.021/23.984 ms\n",
                    "province": "12",
                    "area": "安徽",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 122,
                    "size": 64,
                    "ip": "112.80.248.76",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "安徽宣城联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 13.54,
                    "avg": 13.831,
                    "max": 14.227,
                    "session_id": "venus_1020",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (112.80.248.76): 64 data bytes\n72 bytes from 112.80.248.76: seq=0 ttl=57 time=13.540 ms\n72 bytes from 112.80.248.76: seq=1 ttl=57 time=14.227 ms\n72 bytes from 112.80.248.76: seq=2 ttl=57 time=13.754 ms\n72 bytes from 112.80.248.76: seq=3 ttl=57 time=13.811 ms\n72 bytes from 112.80.248.76: seq=4 ttl=57 time=13.825 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 13.540/13.831/14.227 ms\n",
                    "province": "12",
                    "area": "安徽",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 126,
                    "size": 64,
                    "ip": "220.181.38.150",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "辽宁葫芦岛电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 19.72,
                    "avg": 19.772,
                    "max": 19.84,
                    "session_id": "D4EE0738D8F2",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.150) 64(92) bytes of data.\n72 bytes from 220.181.38.150: icmp_req=1 ttl=54 time=19.7 ms\n72 bytes from 220.181.38.150: icmp_req=2 ttl=54 time=19.7 ms\n72 bytes from 220.181.38.150: icmp_req=3 ttl=54 time=19.8 ms\n72 bytes from 220.181.38.150: icmp_req=4 ttl=54 time=19.7 ms\n72 bytes from 220.181.38.150: icmp_req=5 ttl=54 time=19.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 19.720/19.772/19.840/0.039 ms",
                    "province": "6",
                    "area": "辽宁",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 127,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "辽宁锦州移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 21.1,
                    "avg": 21.428,
                    "max": 21.68,
                    "session_id": "D4EE0746D964",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from 39.156.66.14: icmp_req=1 ttl=53 time=21.6 ms\n72 bytes from 39.156.66.14: icmp_req=2 ttl=53 time=21.1 ms\n72 bytes from 39.156.66.14: icmp_req=3 ttl=53 time=21.4 ms\n72 bytes from 39.156.66.14: icmp_req=4 ttl=53 time=21.3 ms\n72 bytes from 39.156.66.14: icmp_req=5 ttl=53 time=21.6 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 21.100/21.428/21.680/0.220 ms",
                    "province": "6",
                    "area": "辽宁",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 128,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "辽宁铁岭联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 24.98,
                    "avg": 25.068,
                    "max": 25.14,
                    "session_id": "146B9CEB7DBE",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from 110.242.68.4: icmp_req=1 ttl=55 time=25.0 ms\n72 bytes from 110.242.68.4: icmp_req=2 ttl=55 time=25.0 ms\n72 bytes from 110.242.68.4: icmp_req=3 ttl=55 time=25.0 ms\n72 bytes from 110.242.68.4: icmp_req=4 ttl=55 time=25.1 ms\n72 bytes from 110.242.68.4: icmp_req=5 ttl=55 time=24.9 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 24.980/25.068/25.140/0.051 ms",
                    "province": "6",
                    "area": "辽宁",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 132,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "四川成都电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 39.776,
                    "avg": 39.883,
                    "max": 40.139,
                    "session_id": "thost_12",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=51 time=40.139 ms\n72 bytes from 14.215.177.38: seq=1 ttl=51 time=39.793 ms\n72 bytes from 14.215.177.38: seq=2 ttl=51 time=39.776 ms\n72 bytes from 14.215.177.38: seq=3 ttl=51 time=39.833 ms\n72 bytes from 14.215.177.38: seq=4 ttl=51 time=39.877 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 39.776/39.883/40.139 ms\n",
                    "province": "23",
                    "area": "四川",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 133,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "四川绵阳移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 40.68,
                    "avg": 41.06,
                    "max": 41.34,
                    "session_id": "D4EE072CEEB2",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from 39.156.66.14: icmp_req=1 ttl=50 time=41.3 ms\n72 bytes from 39.156.66.14: icmp_req=2 ttl=50 time=41.2 ms\n72 bytes from 39.156.66.14: icmp_req=3 ttl=50 time=40.9 ms\n72 bytes from 39.156.66.14: icmp_req=4 ttl=50 time=41.1 ms\n72 bytes from 39.156.66.14: icmp_req=5 ttl=50 time=40.6 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 12721ms\nrtt min/avg/max/mdev = 40.680/41.060/41.340/0.244 ms",
                    "province": "23",
                    "area": "四川",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 134,
                    "size": 64,
                    "ip": "110.242.68.4",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "四川成都联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 42,
                    "avg": 42.896,
                    "max": 43.38,
                    "session_id": "D4EE072E76CA",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (110.242.68.4) 64(92) bytes of data.\n72 bytes from www.baidu.com (110.242.68.4): icmp_req=1 ttl=54 time=43.3 ms\n72 bytes from www.baidu.com (110.242.68.4): icmp_req=2 ttl=54 time=43.2 ms\n72 bytes from www.baidu.com (110.242.68.4): icmp_req=3 ttl=54 time=42.0 ms\n72 bytes from www.baidu.com (110.242.68.4): icmp_req=4 ttl=54 time=42.9 ms\n72 bytes from www.baidu.com (110.242.68.4): icmp_req=5 ttl=54 time=42.9 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 42.000/42.896/43.380/0.479 ms",
                    "province": "23",
                    "area": "四川",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 138,
                    "size": 64,
                    "ip": "14.215.177.39",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "广西钦州电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 24.56,
                    "avg": 24.828,
                    "max": 25.18,
                    "session_id": "D4EE071C8AFA",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (14.215.177.39) 64(92) bytes of data.\n72 bytes from 14.215.177.39: icmp_req=1 ttl=54 time=25.1 ms\n72 bytes from 14.215.177.39: icmp_req=2 ttl=54 time=24.7 ms\n72 bytes from 14.215.177.39: icmp_req=3 ttl=54 time=24.5 ms\n72 bytes from 14.215.177.39: icmp_req=4 ttl=54 time=24.5 ms\n72 bytes from 14.215.177.39: icmp_req=5 ttl=54 time=25.1 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 24.560/24.828/25.180/0.267 ms",
                    "province": "20",
                    "area": "广西",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 139,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "广西桂林移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 51.26,
                    "avg": 51.76,
                    "max": 51.98,
                    "session_id": "D4EE072B09E6",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from 39.156.66.14: icmp_req=1 ttl=54 time=51.9 ms\n72 bytes from 39.156.66.14: icmp_req=2 ttl=54 time=51.8 ms\n72 bytes from 39.156.66.14: icmp_req=3 ttl=54 time=51.8 ms\n72 bytes from 39.156.66.14: icmp_req=4 ttl=54 time=51.8 ms\n72 bytes from 39.156.66.14: icmp_req=5 ttl=54 time=51.2 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 51.260/51.760/51.980/0.256 ms",
                    "province": "20",
                    "area": "广西",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 140,
                    "size": 64,
                    "ip": "163.177.151.110",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州联通",
                    "node_name": "广西南宁联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 12.88,
                    "avg": 13.028,
                    "max": 13.18,
                    "session_id": "D4EE073F572E",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (163.177.151.110) 64(92) bytes of data.\n72 bytes from 163.177.151.110: icmp_req=1 ttl=56 time=13.1 ms\n72 bytes from 163.177.151.110: icmp_req=2 ttl=56 time=12.9 ms\n72 bytes from 163.177.151.110: icmp_req=3 ttl=56 time=13.0 ms\n72 bytes from 163.177.151.110: icmp_req=4 ttl=56 time=13.0 ms\n72 bytes from 163.177.151.110: icmp_req=5 ttl=56 time=12.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4010ms\nrtt min/avg/max/mdev = 12.880/13.028/13.180/0.098 ms",
                    "province": "20",
                    "area": "广西",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 144,
                    "size": 64,
                    "ip": "220.181.38.150",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "新疆乌鲁木齐电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 60.668,
                    "avg": 60.854,
                    "max": 61.117,
                    "session_id": "thost_7",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (220.181.38.150): 64 data bytes\n72 bytes from 220.181.38.150: seq=0 ttl=44 time=61.117 ms\n72 bytes from 220.181.38.150: seq=1 ttl=44 time=60.859 ms\n72 bytes from 220.181.38.150: seq=2 ttl=44 time=60.854 ms\n72 bytes from 220.181.38.150: seq=3 ttl=44 time=60.668 ms\n72 bytes from 220.181.38.150: seq=4 ttl=44 time=60.776 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 60.668/60.854/61.117 ms\n",
                    "province": "31",
                    "area": "新疆",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 145,
                    "size": 64,
                    "ip": "39.156.66.18",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "新疆昌吉回族自治州移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 79.68,
                    "avg": 79.748,
                    "max": 79.9,
                    "session_id": "D4EE070A9182",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.18) 64(92) bytes of data.\n72 bytes from 39.156.66.18: icmp_req=1 ttl=51 time=79.9 ms\n72 bytes from 39.156.66.18: icmp_req=2 ttl=51 time=79.6 ms\n72 bytes from 39.156.66.18: icmp_req=3 ttl=51 time=79.6 ms\n72 bytes from 39.156.66.18: icmp_req=4 ttl=51 time=79.7 ms\n72 bytes from 39.156.66.18: icmp_req=5 ttl=51 time=79.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4004ms\nrtt min/avg/max/mdev = 79.680/79.748/79.900/0.080 ms",
                    "province": "31",
                    "area": "新疆",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 146,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "新疆巴音郭楞蒙古自治州联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 62.46,
                    "avg": 62.588,
                    "max": 62.86,
                    "session_id": "D4EE075C0D04",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from 39.156.66.14: icmp_req=1 ttl=48 time=62.8 ms\n72 bytes from 39.156.66.14: icmp_req=2 ttl=48 time=62.5 ms\n72 bytes from 39.156.66.14: icmp_req=3 ttl=48 time=62.5 ms\n72 bytes from 39.156.66.14: icmp_req=4 ttl=48 time=62.4 ms\n72 bytes from 39.156.66.14: icmp_req=5 ttl=48 time=62.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4007ms\nrtt min/avg/max/mdev = 62.460/62.588/62.860/0.140 ms",
                    "province": "31",
                    "area": "新疆",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 150,
                    "size": 64,
                    "ip": "220.181.38.149",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "黑龙江佳木斯电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 32.78,
                    "avg": 33.504,
                    "max": 35.52,
                    "session_id": "D4EE073AEF7E",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.149) 64(92) bytes of data.\n72 bytes from 220.181.38.149: icmp_req=1 ttl=54 time=33.2 ms\n72 bytes from 220.181.38.149: icmp_req=2 ttl=54 time=32.9 ms\n72 bytes from 220.181.38.149: icmp_req=3 ttl=54 time=33.0 ms\n72 bytes from 220.181.38.149: icmp_req=4 ttl=54 time=35.5 ms\n72 bytes from 220.181.38.149: icmp_req=5 ttl=54 time=32.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 32.780/33.504/35.520/1.020 ms",
                    "province": "8",
                    "area": "黑龙江",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 152,
                    "size": 64,
                    "ip": "110.242.68.3",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "黑龙江大庆联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 33.78,
                    "avg": 34.212,
                    "max": 34.46,
                    "session_id": "D4EE0737B174",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.3) 64(92) bytes of data.\n72 bytes from 110.242.68.3: icmp_req=1 ttl=54 time=34.4 ms\n72 bytes from 110.242.68.3: icmp_req=2 ttl=54 time=33.7 ms\n72 bytes from 110.242.68.3: icmp_req=3 ttl=54 time=34.2 ms\n72 bytes from 110.242.68.3: icmp_req=4 ttl=54 time=34.2 ms\n72 bytes from 110.242.68.3: icmp_req=5 ttl=54 time=34.3 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4006ms\nrtt min/avg/max/mdev = 33.780/34.212/34.460/0.232 ms",
                    "province": "8",
                    "area": "黑龙江",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 156,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "海南海口电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 11.74,
                    "avg": 12.048,
                    "max": 12.3,
                    "session_id": "D4EE07084702",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (14.215.177.38) 64(92) bytes of data.\n72 bytes from 14.215.177.38: icmp_req=1 ttl=55 time=12.2 ms\n72 bytes from 14.215.177.38: icmp_req=2 ttl=55 time=12.0 ms\n72 bytes from 14.215.177.38: icmp_req=3 ttl=55 time=12.3 ms\n72 bytes from 14.215.177.38: icmp_req=4 ttl=55 time=11.7 ms\n72 bytes from 14.215.177.38: icmp_req=5 ttl=55 time=11.9 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 11.740/12.048/12.300/0.214 ms",
                    "province": "21",
                    "area": "海南",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 157,
                    "size": 64,
                    "ip": "183.232.231.172",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州移动",
                    "node_name": "海南三亚移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 27.083,
                    "avg": 27.464,
                    "max": 28.139,
                    "session_id": "venus_1010",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (183.232.231.172): 64 data bytes\n72 bytes from 183.232.231.172: seq=0 ttl=57 time=27.259 ms\n72 bytes from 183.232.231.172: seq=1 ttl=57 time=27.568 ms\n72 bytes from 183.232.231.172: seq=2 ttl=57 time=27.273 ms\n72 bytes from 183.232.231.172: seq=3 ttl=57 time=27.083 ms\n72 bytes from 183.232.231.172: seq=4 ttl=57 time=28.139 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 27.083/27.464/28.139 ms\n",
                    "province": "21",
                    "area": "海南",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 162,
                    "size": 64,
                    "ip": "14.215.177.38",
                    "is_invalid_ip": 0,
                    "ip_from": "广东广州电信",
                    "node_name": "重庆电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 36.029,
                    "avg": 36.099,
                    "max": 36.169,
                    "session_id": "thost_8",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (14.215.177.38): 64 data bytes\n72 bytes from 14.215.177.38: seq=0 ttl=46 time=36.169 ms\n72 bytes from 14.215.177.38: seq=1 ttl=46 time=36.141 ms\n72 bytes from 14.215.177.38: seq=2 ttl=46 time=36.060 ms\n72 bytes from 14.215.177.38: seq=3 ttl=46 time=36.098 ms\n72 bytes from 14.215.177.38: seq=4 ttl=46 time=36.029 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 36.029/36.099/36.169 ms\n",
                    "province": "22",
                    "area": "重庆",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 163,
                    "size": 64,
                    "ip": "39.156.66.14",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "重庆移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 36.76,
                    "avg": 37.236,
                    "max": 37.76,
                    "session_id": "D4EE0728997E",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (39.156.66.14) 64(92) bytes of data.\n72 bytes from 39.156.66.14: icmp_req=1 ttl=52 time=37.7 ms\n72 bytes from 39.156.66.14: icmp_req=2 ttl=52 time=37.3 ms\n72 bytes from 39.156.66.14: icmp_req=3 ttl=52 time=37.4 ms\n72 bytes from 39.156.66.14: icmp_req=4 ttl=52 time=36.7 ms\n72 bytes from 39.156.66.14: icmp_req=5 ttl=52 time=36.9 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 9151ms\nrtt min/avg/max/mdev = 36.760/37.236/37.760/0.362 ms",
                    "province": "22",
                    "area": "重庆",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 164,
                    "size": 64,
                    "ip": "112.80.248.76",
                    "is_invalid_ip": 0,
                    "ip_from": "江苏南京联通",
                    "node_name": "重庆联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 35.8,
                    "avg": 37.928,
                    "max": 40.54,
                    "session_id": "D4EE07497C20",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (112.80.248.76) 64(92) bytes of data.\n72 bytes from 112.80.248.76: icmp_req=1 ttl=56 time=36.8 ms\n72 bytes from 112.80.248.76: icmp_req=2 ttl=56 time=36.9 ms\n72 bytes from 112.80.248.76: icmp_req=3 ttl=56 time=40.5 ms\n72 bytes from 112.80.248.76: icmp_req=4 ttl=56 time=35.8 ms\n72 bytes from 112.80.248.76: icmp_req=5 ttl=56 time=39.5 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4014ms\nrtt min/avg/max/mdev = 35.800/37.928/40.540/1.803 ms",
                    "province": "22",
                    "area": "重庆",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 168,
                    "size": 64,
                    "ip": "220.181.38.150",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "宁夏银川电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 21.3,
                    "avg": 21.572,
                    "max": 22,
                    "session_id": "D4EE072C8F60",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (220.181.38.150): 64 data bytes\n72 bytes from 220.181.38.150: seq=0 ttl=53 time=21.520 ms\n72 bytes from 220.181.38.150: seq=1 ttl=53 time=21.640 ms\n72 bytes from 220.181.38.150: seq=2 ttl=53 time=22.000 ms\n72 bytes from 220.181.38.150: seq=3 ttl=53 time=21.400 ms\n72 bytes from 220.181.38.150: seq=4 ttl=53 time=21.300 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 21.300/21.572/22.000 ms",
                    "province": "30",
                    "area": "宁夏",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 169,
                    "size": 64,
                    "ip": "39.156.66.18",
                    "is_invalid_ip": 0,
                    "ip_from": "北京移动",
                    "node_name": "宁夏银川移动",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 20.8,
                    "avg": 20.916,
                    "max": 21.12,
                    "session_id": "D4EE0742D564",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (39.156.66.18) 64(92) bytes of data.\n72 bytes from 39.156.66.18: icmp_req=1 ttl=53 time=21.1 ms\n72 bytes from 39.156.66.18: icmp_req=2 ttl=53 time=20.8 ms\n72 bytes from 39.156.66.18: icmp_req=3 ttl=53 time=20.8 ms\n72 bytes from 39.156.66.18: icmp_req=4 ttl=53 time=20.9 ms\n72 bytes from 39.156.66.18: icmp_req=5 ttl=53 time=20.8 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4004ms\nrtt min/avg/max/mdev = 20.800/20.916/21.120/0.109 ms",
                    "province": "30",
                    "area": "宁夏",
                    "isp": "2",
                    "isp_name": "移动"
                },
                {
                    "node_id": 170,
                    "size": 64,
                    "ip": "110.242.68.3",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "宁夏吴忠联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 22.02,
                    "avg": 22.24,
                    "max": 22.46,
                    "session_id": "D4EE072C153A",
                    "host": "www.baidu.com",
                    "contact": "PING www.baidu.com (110.242.68.3): 64 data bytes\n72 bytes from 110.242.68.3: seq=0 ttl=52 time=22.460 ms\n72 bytes from 110.242.68.3: seq=1 ttl=52 time=22.020 ms\n72 bytes from 110.242.68.3: seq=2 ttl=52 time=22.260 ms\n72 bytes from 110.242.68.3: seq=3 ttl=52 time=22.260 ms\n72 bytes from 110.242.68.3: seq=4 ttl=52 time=22.200 ms\n\n--- www.baidu.com ping statistics ---\n5 packets transmitted, 5 packets received, 0% packet loss\nround-trip min/avg/max = 22.020/22.240/22.460 ms",
                    "province": "30",
                    "area": "宁夏",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 174,
                    "size": 64,
                    "ip": "220.181.38.150",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "甘肃定西电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 40.06,
                    "avg": 40.5,
                    "max": 41.54,
                    "session_id": "EC3DFDEDC29E",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.150) 64(92) bytes of data.\n72 bytes from 220.181.38.150: icmp_req=1 ttl=53 time=41.5 ms\n72 bytes from 220.181.38.150: icmp_req=2 ttl=53 time=40.3 ms\n72 bytes from 220.181.38.150: icmp_req=3 ttl=53 time=40.0 ms\n72 bytes from 220.181.38.150: icmp_req=4 ttl=53 time=40.0 ms\n72 bytes from 220.181.38.150: icmp_req=5 ttl=53 time=40.4 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4009ms\nrtt min/avg/max/mdev = 40.060/40.500/41.540/0.543 ms",
                    "province": "28",
                    "area": "甘肃",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 176,
                    "size": 64,
                    "ip": "",
                    "is_invalid_ip": 0,
                    "ip_from": "*",
                    "node_name": "甘肃联通",
                    "packets": 0,
                    "received": 0,
                    "packet_loss": 0,
                    "min": "*",
                    "avg": "*",
                    "max": "*",
                    "session_id": "",
                    "host": "www.baidu.com",
                    "contact": "*",
                    "province": "28",
                    "area": "甘肃",
                    "isp": "3",
                    "isp_name": "联通"
                },
                {
                    "node_id": 180,
                    "size": 64,
                    "ip": "220.181.38.149",
                    "is_invalid_ip": 0,
                    "ip_from": "北京电信",
                    "node_name": "青海西宁电信",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 26.94,
                    "avg": 28.336,
                    "max": 28.82,
                    "session_id": "D4EE0759302C",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (220.181.38.149) 64(92) bytes of data.\n72 bytes from 220.181.38.149: icmp_req=1 ttl=54 time=26.9 ms\n72 bytes from 220.181.38.149: icmp_req=2 ttl=54 time=28.4 ms\n72 bytes from 220.181.38.149: icmp_req=3 ttl=54 time=28.8 ms\n72 bytes from 220.181.38.149: icmp_req=4 ttl=54 time=28.7 ms\n72 bytes from 220.181.38.149: icmp_req=5 ttl=54 time=28.7 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4005ms\nrtt min/avg/max/mdev = 26.940/28.336/28.820/0.710 ms",
                    "province": "29",
                    "area": "青海",
                    "isp": "1",
                    "isp_name": "电信"
                },
                {
                    "node_id": 182,
                    "size": 64,
                    "ip": "110.242.68.3",
                    "is_invalid_ip": 0,
                    "ip_from": "河北保定联通",
                    "node_name": "青海西宁联通",
                    "packets": 5,
                    "received": 5,
                    "packet_loss": 0,
                    "min": 31.9,
                    "avg": 32.124,
                    "max": 32.36,
                    "session_id": "D4EE073999E6",
                    "host": "www.baidu.com",
                    "contact": "PING www.a.shifen.com (110.242.68.3) 64(92) bytes of data.\n72 bytes from 110.242.68.3: icmp_req=1 ttl=53 time=32.3 ms\n72 bytes from 110.242.68.3: icmp_req=2 ttl=53 time=32.0 ms\n72 bytes from 110.242.68.3: icmp_req=3 ttl=53 time=32.2 ms\n72 bytes from 110.242.68.3: icmp_req=4 ttl=53 time=32.0 ms\n72 bytes from 110.242.68.3: icmp_req=5 ttl=53 time=31.9 ms\n\n--- www.a.shifen.com ping statistics ---\n5 packets transmitted, 5 received, 0% packet loss, time 4004ms\nrtt min/avg/max/mdev = 31.900/32.124/32.360/0.169 ms",
                    "province": "29",
                    "area": "青海",
                    "isp": "3",
                    "isp_name": "联通"
                }
            ],
            total: 173,
            time: '2021-06-13 12:32:31',
            type: 'ping'
        }
    }
}

export default {
    fetchData
}