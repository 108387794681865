<template>
  <div class="Echarts">
    <Row style="margin-bottom: 20px">
      <Col span="12">
        <div id="map" style="width: 100%;height: 700px"></div>
      </Col>
      <Col span="12" style="padding-left: 12px">
        <Card :bordered="true" shadow style="margin-bottom: 16px">
          <p slot="title" style="text-align: left">
            <i class="iconfont icon-icon-" style="color: #2db7f5;font-size: 20px"></i>
            区域运营商
          </p>
          <Form
              ref="mapInfo"
              :model="mapInfo"
              label-colon
              :label-width="80"
              label-position="right"
              style="text-align: left">
            <FormItem label="运营商" prop="gender">
              <RadioGroup v-model="mapInfo.isp" size="small">
                <Radio label="0" border>全部</Radio>
                <Radio label="1" border>电信</Radio>
                <Radio label="2" border>移动</Radio>
                <Radio label="3" border>联通</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="区 域" prop="gender">
              <RadioGroup v-model="mapInfo.area" size="small">
                <Radio label="all" border>全部</Radio>
                <Radio label="hua_bei" border>华北</Radio>
                <Radio label="dong_bei" border>东北</Radio>
                <Radio label="hua_dong" border>华东</Radio>
                <Radio label="hua_zhong" border>华中</Radio>
                <Radio label="hua_nan" border>华南</Radio>
                <Radio label="xi_nan" border>西南</Radio>
                <Radio label="xi_bei" border>西北</Radio>
              </RadioGroup>
            </FormItem>
          </Form>
        </Card>
        <Card :bordered="false" shadow style="margin-bottom: 16px">
          <p slot="title" style="text-align: left">
            <i class="iconfont icon-drxx51"></i>
            检测汇总
          </p>
          <Row>
            <Col span="6" style="font-size: 20px;font-weight: bold">
              1
            </Col>
            <Col span="6" style="font-size: 20px;font-weight: bold">
              <span style="color: #19be6b">
                {{ispMaxMin.min.cost}}ms
              </span>
            </Col>
            <Col span="6" style="font-size: 20px;font-weight: bold">
              <span style="color: #ff9900">
                {{ispMaxMin.max.cost}}ms
              </span>
            </Col>
            <Col span="6" style="font-size: 20px;font-weight: bold">
              <span style="color: #ff9900">
                {{ispMaxMin.avg.cost}}ms
              </span>
            </Col>
          </Row>
          <Row>
            <Col span="6">检测节点数</Col>
            <Col span="6">
              {{ispMaxMin.min.node_name}}
              (<span style="color: #19be6b">最快</span>)
            </Col>
            <Col span="6">
              {{ispMaxMin.max.node_name}}(<span style="color: #ff9900">最慢</span>)
            </Col>
            <Col span="6">平均响应</Col>
          </Row>
        </Card>
        <Card :bordered="false" shadow style="padding: 0">
          <p slot="title" style="text-align: left">
            <i class="iconfont icon-drxx51" style="color: #19be6b;font-size: 20px"></i>
            检测详情
          </p>
          <Row type="flex" style="margin-bottom: 10px" v-if="mapInfo.area==='all' && mapInfo.isp === '0'">
            <Col span="24" style="margin-bottom: 6px;">
              <Table border :columns="ispMaxMinColumns" :data="ispMaxMinData">
                <template slot-scope="{ row }" slot="line">
                  <span>{{ row.name }}</span>
                </template>
                <template slot-scope="{ row }" slot="max">
                  <span>
                     {{ row.data.min.node_name }}
                  </span>
                  <span :style="{'float': 'right', 'color': row.data.min.cost<30 ? '#19be6b': row.data.min.cost>=30&&row.data.min.cost<50 ? '#ff9900' : '#ed4014'}">
                    {{ row.data.min.cost }}
                  </span>
                </template>
                <template slot-scope="{ row }" slot="min">
                  <span>
                    {{ row.data.max.node_name }}
                  </span>
                  <span :style="{'float': 'right', 'color': row.data.max.cost<30 ? '#19be6b': row.data.max.cost>=30&&row.data.max.cost<50 ? '#ff9900' : '#ed4014'}">
                    {{ row.data.max.cost }}
                  </span>
                </template>
                <template slot-scope="{ row }" slot="avg">
                  <span :style="{'float': 'right', 'color': row.data.avg.cost<30 ? '#19be6b': row.data.avg.cost>=30&&row.data.avg.cost<50 ? '#ff9900' : '#ed4014'}">
                    {{ row.data.avg.cost }}
                  </span>
                </template>
              </Table>
            </Col>
          </Row>
          <Row type="flex" :gutter="16" style="margin-bottom: 10px" v-else>
            <Col
                span="6"
                v-for="obj in ispByProvinceArr"
                :key="obj._index"
                style="margin-bottom: 6px;
                text-align: left"
            >
              <Tag
                  type="dot"
                  :color="obj.value<30 ? 'success': obj.value>=30&&obj.value<50 ? 'warning' : 'error'"
              >
                {{ obj.name }}-{{ obj.isp_name }} | {{ obj.value.toFixed(2) }}ms
              </Tag>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <Card :bordered="true" shadow>
          <p slot="title" style="text-align: left">
            <i class="iconfont icon-iconfonticon-baobia" style="color: #2db7f5"></i>
            网络检测详情
          </p>
          <Form :model="networkDetail">
            <FormItem label="运营商" prop="isp" style="text-align: left">
              <RadioGroup v-model="networkDetail.isp" size="small">
                <Radio label="0" border>全部</Radio>
                <Radio label="1" border>电信</Radio>
                <Radio label="2" border>移动</Radio>
                <Radio label="3" border>联通</Radio>
              </RadioGroup>
            </FormItem>
          </Form>
        </Card>
        <Table
            max-height="800"
            size="small"
            :columns="netDetailColumns"
            :data="netDetailData"
        >
        </Table>
      </Col>
    </Row>
  </div>
</template>

<script>
import ExpandRow from "./IndexTableExpand"
import api from "@/api/network.js"

export default {
  data() {
    return {
      myChart: {},
      mapInfo: {
        isp: '0',
        area: 'all'
      },
      networkDetail: {
        isp: '0'
      },
      ispMaxMin: {
        "max": {
          "node_name": "",
          "cost": 0
        },
        "min": {
          "node_name": "",
          "cost": 0
        },
        "avg": {
          "node_name": "",
          "cost": 0
        }
      },
      ispByProvinceArr: [],
      netDetailColumns: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(ExpandRow, {
              props: {
                row: params.row
              }
            })
          }
        },
        {
          title: '检测点',
          key: 'ip_from'
        },
        {
          title: '检测IP',
          key: 'ip'
        },
        {
          title: '发送',
          key: 'packets'
        },
        {
          title: '接收',
          key: 'received'
        },
        {
          title: '丢包率%',
          key: 'packet_loss',
          sortable: true
        },
        {
          title: '最大时间',
          key: 'max',
          sortable: true
        },
        {
          title: '最小时间',
          key: 'min',
          sortable: true
        },
        {
          title: '平均时间',
          sortable: true,
          render: (h, {row}) => {
            let cla = row.avg < 50 ? 'table-info-cell' : row.avg>=50 && row.avg < 80 ? 'table-critical-cell' : 'table-emergency-cell';
            return h('span', { class: [cla] }, row.avg)
          }
        },
        {
          title: '包大小',
          key: 'size'
        },
        {
          title: '操作',
          render: (h) => {
            return h('a', {
              'onClick.prevent': $event => console.log('clicked', $event)
            }, '详情')
          }
        }
      ],
      netDetailData: [],
      ispMaxMinColumns: [
        {
          title: '线路',
          slot: 'line',
          width: 80
        },
        {
          title: '最快节点',
          slot: 'max'
        },
        {
          title: '最慢节点',
          slot: 'min'
        },
        {
          title: '平均响应',
          slot: 'avg',
          width: 100
        },
      ],
      ispMaxMinData: [],
      result: {}
    };
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById("map"));
    this.updateEcharts()
  },
  created() {
    this.result = api.fetchData();
    this.netDetailData = this.result.data.result;
    this.ispMaxMinData = Object.values(this.result.data.ispMaxMin);
    this.ispMaxMin = this.result.data.maxMinAvg;
  },
  methods: {
    updateEcharts() {
      let chinaGeoCoordMap = {
        黑龙江: [127.9688, 45.368],
        内蒙古: [110.3467, 41.4899],
        吉林: [125.8154, 44.2584],
        北京市: [116.4551, 40.2539],
        辽宁: [123.1238, 42.1216],
        河北: [114.4995, 38.1006],
        天津: [117.4219, 39.4189],
        山西: [112.3352, 37.9413],
        陕西: [109.1162, 34.2004],
        甘肃: [103.5901, 36.3043],
        宁夏: [106.3586, 38.1775],
        青海: [101.4038, 36.8207],
        新疆: [87.9236, 43.5883],
        西藏: [91.11, 29.97],
        四川: [103.9526, 30.7617],
        重庆: [108.384366, 30.439702],
        山东: [117.1582, 36.8701],
        河南: [113.4668, 34.6234],
        江苏: [118.8062, 31.9208],
        安徽: [117.29, 32.0581],
        湖北: [114.3896, 30.6628],
        浙江: [119.5313, 29.8773],
        福建: [119.4543, 25.9222],
        江西: [116.0046, 28.6633],
        湖南: [113.0823, 28.2568],
        贵州: [106.6992, 26.7682],
        云南: [102.9199, 25.4663],
        广东: [113.12244, 23.009505],
        广西: [108.479, 23.1152],
        海南: [110.3893, 19.8516],
        上海: [121.4648, 31.2891],
      };
      let chinaDatas = [
        [
          {
            name: '黑龙江',
            value: 0,
          },
        ],
        [
          {
            name: '北京市',
            value: 0,
          },
        ],
        [
          {
            name: '内蒙古',
            value: 0,
          },
        ],
        [
          {
            name: '吉林',
            value: 0,
          },
        ],
        [
          {
            name: '辽宁',
            value: 0,
          },
        ],
        [
          {
            name: '河北',
            value: 0,
          },
        ],
        [
          {
            name: '天津',
            value: 0,
          },
        ],
        [
          {
            name: '山西',
            value: 0,
          },
        ],
        [
          {
            name: '陕西',
            value: 0,
          },
        ],
        [
          {
            name: '甘肃',
            value: 0,
          },
        ],
        [
          {
            name: '宁夏',
            value: 0,
          },
        ],
        [
          {
            name: '青海',
            value: 0,
          },
        ],
        [
          {
            name: '新疆',
            value: 5,
          },
        ],
        [
          {
            name: '西藏',
            value: 0,
          },
        ],
        [
          {
            name: '四川',
            value: 0,
          },
        ],
        [
          {
            name: '重庆',
            value: 0,
          },
        ],
        [
          {
            name: '山东',
            value: 0,
          },
        ],
        [
          {
            name: '河南',
            value: 0,
          },
        ],
        [
          {
            name: '江苏',
            value: 0,
          },
        ],
        [
          {
            name: '安徽',
            value: 0,
          },
        ],
        [
          {
            name: '湖北',
            value: 0,
          },
        ],
        [
          {
            name: '浙江',
            value: 0,
          },
        ],
        [
          {
            name: '福建',
            value: 0,
          },
        ],
        [
          {
            name: '江西',
            value: 0,
          },
        ],
        [
          {
            name: '湖南',
            value: 0,
          },
        ],
        [
          {
            name: '贵州',
            value: 0,
          },
        ],
        [
          {
            name: '广西',
            value: 0,
          },
        ],
        [
          {
            name: '海南',
            value: 0,
          },
        ],
        [
          {
            name: '上海',
            value: 0,
          },
        ],
      ];

      // let convertData = function (data) {
      //   let res = [];
      //   for (let i = 0; i < data.length; i++) {
      //     let dataItem = data[i];
      //     let fromCoord = chinaGeoCoordMap[dataItem[0].name];
      //     let toCoord = [116.4551, 40.2539];
      //
      //     if (fromCoord && toCoord) {
      //       res.push([
      //         {
      //           coord: fromCoord,
      //           value: dataItem[0].value,
      //         },
      //         {
      //           coord: toCoord,
      //         },
      //       ]);
      //     }
      //   }
      //   return res;
      // };
      let series = [];
      [['北京市', chinaDatas]].forEach(function (item) {
        series.push(
            {
              type: 'lines',
              zlevel: 2,
              effect: {
                show: true,
                period: 2, //箭头指向速度，值越小速度越快
                trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', //箭头图标
                symbolSize: 5, //图标大小
              },
              tooltip: {
                trigger: 'item',
                backgroundColor: 'rgb(45 140 240 / 20%)',
                showDelay: 0,
                hideDelay: 0,
                enterable: true,
                transitionDuration: 0,
                extraCssText: 'z-index:100',
                formatter: function (params) {
                  //根据业务自己拓展要显示的内容
                  let from = params.data.from
                  let to = params.data.to
                  let value = params.data.value
                  let res
                  res = `
                      <div style="color: #d7dde4">${params.marker}${from}->${to} 网络质量:</div>
                      <div style="color: #d7dde4">RTT平均: ${value}ms</div>
                      <div style="color: #d7dde4">ping平均: ${value}ms</div>
                      <div style="color: #d7dde4">平均延迟: ${value}ms</div>
                  `
                  return res;
                },
              },
              lineStyle: {
                normal: {
                  width: 1, //尾迹线条宽度
                  opacity: 1, //尾迹线条透明度
                  curveness: 0.3, //尾迹线条曲直度
                  color: '#00eaff'
                },
              },
            data: [
                      [{
                        coord: [116.4551, 40.2539],
                      },
                      {
                        from: "北京-联通",
                        to: "黑龙江-联通",
                        coord: [127.9688, 45.368],
                        value: 20,
                      }],
                      [{
                        coord: [121.4648, 31.2891],
                      },
                        {
                          from: "上海-联通",
                          to: "湖北-联通",
                          coord: [114.3896, 30.6628],
                          value: 6,
                        }],
            ]
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              zlevel: 2,
              rippleEffect: { //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: 'stroke', //波纹绘制方式 stroke, fill
                scale: 1 //波纹圆环最大限制，值越大波纹越大
              },
              label: {
                normal: {
                  show: true,
                  position: 'right', //显示位置
                  offset: [5, 0], //偏移设置
                  color : "inherit",
                  formatter: function (params) {
                    //圆环显示文字
                    return params.data.name;
                  },
                  // fontSize: 10,
                },
                emphasis: {
                  show: true,
                },
              },
              symbol: 'circle',
              symbolSize: 5,
              itemStyle: {
                normal: {
                  show: true,
                  color: '#19be6b',
                },
              },
              data: item[1].map(function (dataItem) {
                return {
                  name: dataItem[0].name,
                  value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]),
                };
              }),
            // },
            // data: [{
            //     name: '上海市',
            //     value: [121.4648, 31.2891]
            //   }]
            }
            //被攻击点
            // {
            //   type: 'scatter',
            //   coordinateSystem: 'geo',
            //   zlevel: 2,
            //   rippleEffect: {
            //     period: 4,
            //     brushType: 'stroke',
            //     scale: 4,
            //   },
            //   label: {
            //     normal: {
            //       show: true,
            //       position: 'right',
            //       //offset:[5, 0],
            //       color: '#0f0',
            //       formatter: '{b}',
            //       textStyle: {
            //         color: '#0f0',
            //       },
            //     },
            //     emphasis: {
            //       show: true,
            //       color: '#f60',
            //     },
            //   },
            //   symbol: 'pin',
            //   symbolSize: 50,
            //   data: [
            //     {
            //       name: item[0],
            //       value: chinaGeoCoordMap[item[0]].concat([10]),
            //     },
            //   ],
            // }
        );
      });

      let option = {
        tooltip: {
          // show: true,
        },
        // tooltip: {
        //   trigger: 'item',
        //   backgroundColor: 'rgb(45 140 240 / 20%)',
        //   borderColor: '#FFFFCC',
        //   showDelay: 0,
        //   hideDelay: 0,
        //   enterable: true,
        //   transitionDuration: 0,
        //   extraCssText: 'z-index:100',
        //   formatter: function (params) {
        //     //根据业务自己拓展要显示的内容
        //     let res = '';
        //     let name = params.name;
        //     res = `
        //         <div style="color: #d7dde4">${name}网络质量:</div>
        //         <div style="color: #d7dde4">RTT平均: 55ms</div>
        //         <div style="color: #d7dde4">ping平均: 60ms</div>
        //     `
        //     return res;
        //   },
        // },
        backgroundColor: '#17233d',
        // visualMap: {
        //   //图例值控制
        //   min: 0,
        //   max: 1,
        //   calculable: true,
        //   show: true,
        //   color: ['#f44336', '#fc9700', '#ffde00', '#ffde00', '#00eaff'],
        //   textStyle: {
        //     color: '#fff',
        //   },
        // },
        geo: {
          map: 'china',
          zoom: 1.2,
          scaleLimit: {
            min: 1,
            max: 1.5
          },
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: 'scale', //是否允许缩放
          itemStyle: {
            normal: {
              color: 'rgba(51, 69, 89, .5)', //地图背景色
              borderColor: '#516a89', //省市边界线00fcff 516a89
              borderWidth: 1,
            },
            emphasis: {
              color: 'rgba(37, 43, 61, .5)', //悬浮背景
            },
          },
        },
        series: series,
      };
      this.myChart.setOption(option);
    }
  },
  watch: {
    mapInfo: {
      handler: function (val) {
        //检测汇总
        if(val.isp === "0"){
          this.ispMaxMin = this.result.data.maxMinAvg;
        } else {
          this.ispMaxMin = this.result.data.ispMaxMin[val.isp].data;
        }

        //检测汇总
        if (val.isp === '0' && val.area === 'all') {
          this.ispMaxMinData = Object.values(this.result.data.ispMaxMin);
        } else if (val.isp !== '0' && val.area === 'all') {
          this.ispByProvinceArr = this.result.data.ispByProvinceArr[val.isp];
        } else if (val.isp === '0' && val.area !== 'all') {
          let ispByProvinceArrAll = [...this.result.data.ispByProvinceArr[1],...this.result.data.ispByProvinceArr[2],...this.result.data.ispByProvinceArr[3]];
          this.ispByProvinceArr = ispByProvinceArrAll.filter((e)=> e.seven_area_name === val.area);
        } else {
          this.ispByProvinceArr = this.result.data.ispByProvinceArr[val.isp].filter((e)=> e.seven_area_name === val.area);
        }
      },
      deep: true
    },
    networkDetail: {
      handler: function (val) {
        if(val.isp === '0') {
          this.netDetailData = this.result.data.result;
        } else {
          this.netDetailData = this.result.data.result.filter((e)=> e.isp === val.isp);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
/deep/ .ivu-table-expanded-cell {
  padding: 0;
}

/deep/ .ivu-form-item {
  margin-bottom: 0;
}

/deep/ .ivu-tag {
  width: 100%;
}

/deep/ .ivu-table td div span.table-info-cell {
  color: #19be6b;
}

/deep/ .ivu-table td div span.table-warnning-cell {
  color: rgb(196 221 88);
}

/deep/ .ivu-table td div span.table-critical-cell {
  color: #ff9900;
}

/deep/ .ivu-table td div span.table-emergency-cell {
  color: #ed4014;
}

</style>
